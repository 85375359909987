exports.awsconfig = {
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_T4SlrxY50",
    userPoolWebClientId: "593d78npeuk1q4idlfko0meuf",
    identityPoolId: "us-east-2:e793a8ba-fd33-43e3-8ba0-04e97ab45e45",
  },
};

exports.API_SET_COOKIE = "https://um8gmcahlk.execute-api.us-east-2.amazonaws.com/prod/";

// CdkWebSshStack.ApiCookieEndpoint16218B10 = https://um8gmcahlk.execute-api.us-east-2.amazonaws.com/prod/
// CdkWebSshStack.ApiCookieUrl = https://um8gmcahlk.execute-api.us-east-2.amazonaws.com/prod/
// CdkWebSshStack.AwsRegion = us-east-2
// CdkWebSshStack.UserPoolId = us-east-2_T4SlrxY50
// CdkWebSshStack.UserPoolIdentityId = us-east-2:e793a8ba-fd33-43e3-8ba0-04e97ab45e45
// CdkWebSshStack.UserPoolWebClientId = 593d78npeuk1q4idlfko0meuf